.landingButton {
  background-color: lightgray;
  color: white;
  height: 2.5rem;
  min-width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}
