@import "@styles/variables.scss";

.carousel {
  background-color: #efefef;
  min-height: 350px;
  padding: 2rem 0 5rem 0;

  @media screen and (max-width: $screen-md) {
    padding: 2rem 0;
  }
  &_title {
    text-align: center;
    font-weight: bold;
  }

  &_button {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }
  &_button + button {
    display: block;
    margin: 0 auto;
  }

  &_title {
    &-video {
      font-size: 1rem;
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 0.3rem;
      text-align: center;
      text-transform: lowercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 2.5rem;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  &_subtitle-video {
    font-size: 0.8rem;
    font-weight: bold;
    word-wrap: break-word;
  }

  &_content {
    width: 60%;
    margin: 0 auto;
  }

  &_video {
    height: 40%;
    width: 90% !important;
    padding-top: 20px;
    display: unset;
  }

  :global {
    .ant-carousel {
      width: 100%;
      margin: auto;
    }

    .ant-carousel .slick-list {
      width: 100%;
      height: 90%;
      margin: 0 auto;
      padding-left: 18px;
    }

    .ant-carousel .slick-slider {
      width: auto;
      text-align: center;
      padding: 1rem;
    }

    .ant-carousel .slick-prev,
    .ant-carousel .slick-next,
    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover {
      font-size: 30px;
      color: currentColor;
    }
    .slick-dots,
    .slick-dots-bottom {
      bottom: -28px !important;
      button {
        background: $landing-btn-apply !important;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    :global {
      .ant-carousel .slick-next,
      .ant-carousel .slick-next:hover {
        right: -10px;
      }
      .ant-carousel .slick-prev,
      .ant-carousel .slick-prev:hover {
        left: -10px;
      }
    }
    &_content {
      width: 90%;
    }
  }
}
