@import "@styles/variables.scss";

.imgContainer {
  position: relative;
  width: 100%;
  cursor: pointer;

  &.jobDetail {
    min-height: 400px;
    @media screen and (max-width: $screen-xs) {
      min-height: 259px;
    }
  }

  &.landingDescription {
    min-height: 455px;
    @media screen and (max-width: $screen-xs) {
      min-height: 217px;
    }
  }

  &.carousel {
    min-height: 300px;
    @media screen and (max-width: $screen-xs) {
      min-height: 181px;
    }
  }
}

.playButton {
  top: calc(50% - 29px);
  left: calc(50% - 29px);
  position: absolute;
}
