@import "@styles/variables.scss";

.companyHeader {
  width: 99%;
  transition: all 0.9s linear;

  &_animation {
    animation: 0.9s forwards ease move;
    @keyframes move {
      from {
        transform: translateY(240px);
      }
      to {
        transform: translateY(0px);
      }
    }
  }
  &_banner {
    width: 100%;
    height: 600px;
    text-align: center;
    justify-content: center;
    display: flex !important;
    position: relative;

    @media screen and (max-width: $screen-xxl) {
      height: 550px;
    }
    @media screen and (max-width: $screen-hd) {
      height: 480px;
    }
    @media screen and (max-width: $screen-xl) {
      height: 400px;
    }
    @media screen and (max-width: $screen-lg) {
      height: 320px;
    }
    @media screen and (max-width: $screen-sm) {
      height: 280px;
    }

    &-bannerColor {
      width: 100%;
      height: 300px;
    }
    &-text {
      position: absolute;
      text-align: center;
      top: 30%;
      font-size: 20px;
      min-width: 500px;
      min-height: 150px;
      background-color: rgba(195, 195, 195, 0.296);
      label {
        margin: 15px;
      }
    }
    img {
      height: 100%;
      object-fit: fill;
    }
    &_video {
      :global {
        i {
          font-size: 65px;
          cursor: pointer;
          color: rgb(205, 192, 192);
        }
        i:hover {
          color: white;
        }
      }
    }
    &_modalVideo {
      margin-top: 25px;
    }
  }
  &_title {
    color: black;
    font-family: "Lato";
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 1.2rem;
  }
  &_title::first-letter {
    text-transform: capitalize;
  }
  &_description {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 97vw;
    margin: 0 auto;
    margin-top: 2rem;
    font-weight: 700;
    align-items: center;

    &-video {
      grid-column: 1 / 2;
      padding: 1% 5% 1% 5%;
      text-align: center;
      justify-content: center;
    }
    &-img {
      grid-column: 1 / 2;
      padding: 1% 5% 1% 5%;
      text-align: center;
      justify-content: center;
      img {
        height: 100%;
      }
    }
    &-text {
      height: inherit;
      grid-column: 2/ 3;
      padding: 1% 5% 1% 5%;
      p {
        color: #8a8a8a;
      }
      label {
        color: #2ea9a2;
      }
      img {
        width: 100%;
        max-width: 700px;
      }
    }
    &-text1 {
      grid-column: 2/ 3;
      padding: 1% 5% 1% 5%;

      p {
        color: #8a8a8a;
      }
      label {
        color: #2ea9a2;
      }
    }
  }
  &_companyButton {
    display: flex;
    justify-content: center;
    margin: auto;
  }
  button {
    border-color: transparent;
  }
  button:hover {
    border-color: transparent;
  }

  @media screen and (max-width: $screen-md) {
    &_animation {
      width: 99%;
    }
    &_banner {
      &-text {
        min-width: unset;
      }
    }
    img {
      height: 18rem;
    }
    &_description {
      display: block;
      &-video {
        width: 100%;
      }
      &-text {
        text-align: center;
        p {
          text-align: justify;
        }
      }
    }
  }

  @media screen and (max-width: $screen-lg) {
    display: block;
    &_description {
      &-img {
        margin-top: 20px;
        width: 100%;
      }
      &-text {
        width: 100%;
      }
    }
  }
}
