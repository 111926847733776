@import "@styles/variables.scss";

.footerLanding {
  text-align: center;
  min-height: 300px;
  &_marginText {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .nextImg {
    margin-top: 1rem !important;
  }
  .powered {
    text-align: center;
    background: #ffffff;
    width: 12rem;
    padding: 1rem 0;
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
    &_text {
      color: #b5b5b5;
      font-size: 14px;
      line-height: 15px;
      font-weight: 300;
    }

    &_image {
      height: 36px;
      &_container {
        position: relative;
      }
    }
  }
  h4 {
    margin: 20px;
  }
  img {
    margin: 20px;
    height: 100px;
    width: auto;
  }
  &_icons {
    font-size: 25px;
    margin: 5px;
    cursor: pointer;
  }
}
.btnRegister {
  font-weight: 500;
  background-color: transparent;
  border: none;
  cursor: pointer;
  @media screen and (max-width: $screen-md) {
    margin-bottom: 40px;
  }
}

.links {
  margin-bottom: 30px;
  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    margin-bottom: unset;
  }
}

.link {
  margin: 8px;
  @media screen and (max-width: $screen-md) {
    font-weight: 500;
    margin-bottom: 40px;
  }
}
