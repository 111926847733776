@import "@styles/variables.scss";

.floatLog {
  border: solid 1px rgb(212, 212, 212);
  background-color: white;
  border-radius: 10px;
  height: 180px;
  width: 155px;
  position: fixed;
  right: 2%;
  bottom: 5%;
  z-index: 5;
  &_btnClose {
    font-size: 20px;
    font-weight: 900 !important;
    width: 1.8rem !important;
    height: 1.5rem !important;
    padding: 0 !important;
    position: absolute;
    right: 0;
    z-index: 5;
    border: none;
    box-shadow: none;
    background: transparent !important;
  }
  &_sections {
    text-align: center;
    width: 100%;
  }
  &_img {
    margin-top: 12px;
    width: 100%;
    height: 50px;
  }
  &_text {
    height: 73px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      width: 160px;
    }
  }
  &_button-btnRegister {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: $black;
    border-radius: 20px;
    background: $btn-sign-up;
    width: 7rem;
    height: 28px;
    margin-top: 9px;
    border: none;
    overflow: hidden;
    &:hover {
      background: $btn-sign-up;
      color: $black;
    }
  }
}
